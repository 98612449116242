export function vehiclesResponsePolicy() {
  return {
    fields: {
      vehicles: {
        merge(existing, incoming, { args }) {
          return existing ? [...existing, ...incoming] : incoming;
        },
      },
      filters: {
        read(filters) {
          const year = { ...filters.year };
          delete year['__typename'];
          return { ...filters, year };
        },
      },
    },
  };
}
