import React from 'react';
import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloProvider as Provider,
  ApolloLink,
} from '@apollo/client';
import { setContext } from 'apollo-link-context';
import fetch from 'isomorphic-fetch';
import { vehiclesResponsePolicy } from 'src/services/graphql/cachePolicies';
import { sha256 } from 'crypto-hash';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { onError } from '@apollo/client/link/error';
import { sendErrorsToSentry } from 'shared/helpers/Errors';
import { RetryLink } from 'apollo-link-retry';

export const ApolloProvider = ({ children }) => {
  const httpLink = createHttpLink({
    uri: process.env.GATSBY_GRAPHQL_PATH,
    fetch,
  });
  const persistedQueriesLink = createPersistedQueryLink({
    sha256: sha256,
    useGETForHashedQueries: true,
  });
  const authLink = setContext(async (_, { headers = {} }) => {
    headers.Authorization = headers?.Authorization || 'Bearer';

    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
      },
    };
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    sendErrorsToSentry(networkError, graphQLErrors);
  });

  const retryLink = new RetryLink({
    attempts: (count, operation, error) => {
      return (
        !!error &&
        operation.operationName === 'GetVehiclesAndFilters' &&
        count < 3
      );
    },
    delay: () => {
      return 1000 * Math.random();
    },
  });

  const client = new ApolloClient({
    cache: new InMemoryCache({
      typePolicies: {
        VehiclesResponse: vehiclesResponsePolicy(),
      },
    }),
    link: ApolloLink.from([
      errorLink,
      retryLink,
      persistedQueriesLink.concat(authLink.concat(httpLink)),
    ]),
  });

  return <Provider client={client}>{children}</Provider>;
};
