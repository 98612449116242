export default (() => {
  let instance;

  const createInstance = (
    createConnectionTokenAuthenticated,
    setStripeDisconnected
  ) => {
    return window.StripeTerminal.create({
      onFetchConnectionToken: () =>
        createConnectionTokenAuthenticated().then(
          response => response.data.CreateTerminalConnectionToken.secret
        ),
      onUnexpectedReaderDisconnect: () => {
        // localStorage.removeItem('connectedReaderSN');
        console.error('Reader Disconnected!'); // TODO have popup warning modal
        setStripeDisconnected(true);
      },
    });
  };

  return {
    getInstance: (
      createConnectionTokenAuthenticated,
      setStripeDisconnected
    ) => {
      if (!instance) {
        instance = createInstance(
          createConnectionTokenAuthenticated,
          setStripeDisconnected
        );
      }
      return instance;
    },
  };
})();
