import * as React from 'react';
import loader from 'static/images/loader.gif';

const Spinner = () => (
  <div
    style={{
      display: 'flex',
      position: 'fixed',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 4,
    }}
  >
    <img src={loader} alt="loader" style={{ width: '40px' }} />
  </div>
);

export default Spinner;
