import { navigate } from 'gatsby';
import { useAuth0 } from '@auth0/auth0-react';
import * as amplitude from '@amplitude/analytics-browser';
export const redirectCallbackAfterLogin = () => {
  const currentRoute = localStorage.getItem('currentRoute') || '/';
  return navigate(currentRoute);
};

export const useCheckNotAuthenticatedUsers = () => {
  const { isAuthenticated, user } = useAuth0();
  const isBrowser = typeof window !== `undefined`;
  if (
    !isAuthenticated &&
    isBrowser &&
    window.location.pathname !== `/auth/login`
  ) {
    return true;
  } else if (
    isAuthenticated &&
    !user.email_verified &&
    isBrowser &&
    window.location.pathname !== `/auth/login`
  ) {
    return 'unverified';
  }
};
