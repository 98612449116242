import React from 'react';
import { sendErrorsToSentry } from 'shared/helpers/Errors';
import FeatureToggleManager from 'shared/helpers/FeatureToggle/featureToggleManager';
import { DISABLE_ERROR_BOUNDARIES_REDIRECT } from 'shared/helpers/FeatureToggle/constants';

const featureToggleManager = new FeatureToggleManager();
class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // call error logging or reporting service
    sendErrorsToSentry(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      const isBrowser = typeof window !== undefined;
      if (
        isBrowser &&
        !featureToggleManager.isEnabled(DISABLE_ERROR_BOUNDARIES_REDIRECT)
      ) {
        window.location.replace('/error');
      }
      return null;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
