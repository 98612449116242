export const DEV = 'dev';
export const PROD = 'prod';
export const NULL = 'null';

// Features Part
export const AUCTION_MOBILITY_WIDGET = 'auction-mobility-widget';
export const AUCTION_MOBILITY_LOGOUT = 'auction-mobility-logout';
export const DISABLE_ERROR_BOUNDARIES_REDIRECT =
  'disable-error-boundaries-redirect';

export const features = {
  [AUCTION_MOBILITY_WIDGET]: { environment: NULL },
  [AUCTION_MOBILITY_LOGOUT]: { environment: PROD },
  [DISABLE_ERROR_BOUNDARIES_REDIRECT]: { environment: DEV },
};
