import { DEV, PROD, NULL } from './constants';

export function isEnabledForCurrentStage(feature_environment) {
  const env = process.env.GATSBY_STAGE;
  if (!feature_environment) return false;
  switch (feature_environment) {
    case NULL:
      return false;
    case DEV:
      return env === DEV;
    case PROD:
      return true;
    default:
      return false;
  }
}
