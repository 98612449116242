import React, { useContext, useEffect, useState } from 'react';
import { DEV } from 'shared/helpers/FeatureToggle/constants';
import StripeTerminalContext from 'shared/context/StripeTerminalContext';
import Modal from 'components/Modal';
import { navigate } from 'gatsby';

const StripeDisconnectHandler = ({
  stripeDisconnected,
  failedToFetchToken,
  setStripeDisconnected,
}) => {
  const { stripeTerminal } = useContext(StripeTerminalContext);
  const [showModal, toggleModal] = useState({
    show: false,
    text: '',
    disableToggling: true,
  });
  useEffect(() => {
    if (stripeDisconnected) {
      const preConnectedReaderSN = localStorage.getItem('connectedReaderSN');
      localStorage.removeItem('connectedReaderSN');
      reconnect(
        stripeTerminal,
        preConnectedReaderSN,
        toggleModal,
        setStripeDisconnected
      );
    }
  }, [stripeDisconnected]);
  useEffect(() => {
    if (failedToFetchToken) {
      toggleModal({
        show: true,
        text: 'Refresh the page to try again',
        disableToggling: true,
        title: 'Error Connecting to Reader',
      });
      navigate('/auth/link-kiosk', {
        state: { navigateToKiosk: true },
      });
    }
  }, [failedToFetchToken]);
  return (
    <>
      {showModal.show && (
        <Modal
          title={showModal.title ?? 'Reader disconnected'}
          bodyText={showModal.text}
          isOpen={showModal.show}
          disableToggling={showModal.disableToggling}
        />
      )}
    </>
  );
};

export default StripeDisconnectHandler;

async function reconnect(
  stripeTerminal,
  preConnectedReaderSN,
  toggleModal,
  setStripeDisconnected
) {
  toggleModal({ show: true, text: 'Reconnecting...', disableToggling: true });
  // await new Promise(res => setTimeout(res, 20000));
  // console.log('wait end');
  const location = process.env.GATSBY_STRIPE_LOCATION;
  const simulator = process.env.GATSBY_STRIPE_SIMULATOR === 'true';
  setStripeDisconnected(false);
  stripeTerminal
    .discoverReaders({
      simulated: simulator,
      ...(location && { location }),
    })
    .then(async ({ discoveredReaders, error }) => {
      if (error) {
        console.error(
          'Error when attempting to discover nearby readers: ',
          error
        );
      } else {
        const preConnectedReader = discoveredReaders.find(
          r => r.serial_number === preConnectedReaderSN
        );
        if (preConnectedReader) {
          const success = await stripeTerminal
            .connectReader(preConnectedReader, { fail_if_in_use: true })
            .then(({ error }) => {
              if (!error) {
                localStorage.setItem(
                  'connectedReaderSN',
                  preConnectedReader.serial_number
                );
                toggleModal({ show: false, text: '' });
                return true;
              } else return false;
            });
          if (success) return;
        }

        toggleModal({
          show: true,
          text:
            'Automatic reconnection failed. Please reach out to an admin for support',
          disableToggling: false,
        });

        navigate('/auth/link-kiosk', {
          state: { navigateToKiosk: true },
        });
      }
    });
}
