import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { useAuthenticatedMutation } from './src/services/graphql/hooks';
import { ApolloProvider } from './src/services/graphql/ApolloProvider';
import { CREATE_TERMINAL_CONNECTION_TOKEN } from './src/services/graphql/queries/stripe/index.js';
import { config } from './src/services/authentication/auth_config';
import 'reset-css';
import { redirectCallbackAfterLogin } from './src/shared/helpers/Auth';
import Spinner from './src/components/Spinner';
import ErrorBoundary from './src/features/ErrorBoundaries';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { loadStripeTerminal } from '@stripe/terminal-js';
import StripeTerminal from './src/shared/helpers/stripe/StripeTerminal';
import StripeTerminalContext from './src/shared/context/StripeTerminalContext';
import { DEV } from 'shared/helpers/FeatureToggle/constants';
import { Roles, RolesKey } from 'utils/RolesConstants';
import './src/styles/index.scss';
import StripeDisconnectHandler from 'components/StripeDisconnectHandler';
import CookieConsent from 'react-cookie-consent';
import * as amplitude from '@amplitude/analytics-browser';

export const wrapRootElement = ({ element }) => {
  const stripePromise = loadStripe(process.env.GATSBY_STRIPE_KEY);
  loadStripeTerminal();

  const SessionWrapper = ({ children }) => {
    const [stripeTerminal, setStripeTerminal] = useState();
    const [readerConnected, setReaderConnected] = useState();
    const [stripeDisconnected, setStripeDisconnected] = useState(false);
    const { user, isAuthenticated, isLoading } = useAuth0();

    const [
      createTerminalConnectionToken,
      { error: failedToFetchToken },
    ] = useAuthenticatedMutation(CREATE_TERMINAL_CONNECTION_TOKEN);

    const loadStripeTerminal =
      window.StripeTerminal && user?.[RolesKey].includes(Roles.kioskAdmin);

    useEffect(() => {
      amplitude.init(process.env.GATSBY_AMPLITUDE_API);
      if (loadStripeTerminal) {
        setStripeTerminal(
          StripeTerminal.getInstance(
            createTerminalConnectionToken,
            setStripeDisconnected
          )
        );
      }
    }, [loadStripeTerminal]);

    // Try to reconnect to a previously linked reader after refresh/new tab/sudden disconnect
    useEffect(() => {
      if (stripeTerminal) {
        const location = process.env.GATSBY_STRIPE_LOCATION;
        const simulator = process.env.GATSBY_STRIPE_SIMULATOR === 'true';
        stripeTerminal
          .discoverReaders({
            simulated: simulator,
            ...(location && { location }),
          })
          .then(({ discoveredReaders, error }) => {
            if (error) {
              console.error(
                'Error when attempting to discover nearby readers: ',
                error
              );
            } else {
              const preConnectedReader = discoveredReaders.find(
                r =>
                  r.serial_number === localStorage.getItem('connectedReaderSN')
              );
              if (preConnectedReader) {
                stripeTerminal
                  .connectReader(preConnectedReader, { fail_if_in_use: true })
                  .then(({ error }) => {
                    if (error) {
                      console.error(
                        'Error when attempting to link device: ',
                        error
                      );
                      localStorage.removeItem('connectedReaderSN'); // clear saved device on reconnection failure
                    } else {
                      setReaderConnected(true);
                    }
                  });
              } else localStorage.removeItem('connectedReaderSN'); // clear saved device on reconnection failure
            }
          });
      }
    }, [stripeTerminal]);

    if (isAuthenticated && !user.email_verified) {
      amplitude.track('Successful sign up', { email_address: user?.email });
      navigate('/auth/unverified');
    }

    return (
      <ErrorBoundary>
        <Elements stripe={stripePromise}>
          <StripeTerminalContext.Provider
            value={{ stripeTerminal, reconnected: readerConnected }}
          >
            {isLoading && <Spinner />}
            {children}
            <StripeDisconnectHandler
              stripeDisconnected={stripeDisconnected}
              failedToFetchToken={failedToFetchToken}
              setStripeDisconnected={setStripeDisconnected}
            />
            <CookieConsent
              location="bottom"
              buttonText="ACCEPT"
              cookieName="gatsby-consent" //cookie that will be set in the browser after the user clicks on accept
              style={{
                background: '#f6f6f6',
                color: 'black',
                padding: '15px',
                fontSize: '14px',
                alignItems: 'center',
              }}
              expires={365}
              contentStyle={{ marginRight: '20px' }}
              buttonClasses="button app-primary-button linkAsButton"
              // debug
              overlay
              disableButtonStyles
              overlayStyle={{ backgroundColor: 'rgb(21 20 20 / 53%)' }}
            >
              Gooding & Company asks you to accept cookies for marketing and
              performance purposes. For more information about these cookies or
              to amend your preferences, press the “More Information” button to
              visit our Privacy Policy page. Do you accept these cookies and the
              processing of personal data?{' '}
              <a style={{ color: '#007bff' }} href="/privacy">
                More Information.
              </a>
            </CookieConsent>
          </StripeTerminalContext.Provider>
        </Elements>
      </ErrorBoundary>
    );
  };

  return (
    <Auth0Provider
      domain={config.domain}
      clientId={config.client_id}
      redirectUri={window.location.origin}
      scope={config.scope}
      audience={config.audience}
      onRedirectCallback={redirectCallbackAfterLogin}
    >
      <ApolloProvider>
        <SessionWrapper>{element}</SessionWrapper>
      </ApolloProvider>
    </Auth0Provider>
  );
};
