import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';

export const useAuthenticatedQuery = (query, options = {}) => {
  const { isLoading, getAccessTokenSilently } = useAuth0();
  const [token, setToken] = useState('');
  const [skip, setSkip] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const skippedByUser = options.skip;
    if (!skippedByUser && !isLoading) {
      getAccessTokenSilently().then(token => {
        setToken(token);
        setSkip(false);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [options, isLoading, getAccessTokenSilently]);

  const { loading: queryLoading, ...rest } = useQuery(query, {
    ...options,
    skip,
    context: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  });

  return { loading: loading || queryLoading, ...rest };
};

export const useAuthenticatedMutation = (mutation, options = {}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [mutate, mutationResult] = useMutation(mutation, options);

  return [
    async (options = {}) => {
      let token = '';
      const skipToken = options.skipToken;
      if (!skipToken) {
        const silentToken = await getAccessTokenSilently();
        token = silentToken;
      }
      return mutate({
        ...options,
        context: { headers: { Authorization: `Bearer ${token}` } },
      });
    },
    mutationResult,
  ];
};

export const useAuthenticatedLazyQuery = (query, options = {}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [excuteQuery, queryResult] = useLazyQuery(query, options);

  return [
    async (options = {}) => {
      let token = '';
      const skipToken = options.skipToken;
      if (!skipToken) {
        const silentToken = await getAccessTokenSilently();
        token = silentToken;
      }
      return excuteQuery({
        ...options,
        context: { headers: { Authorization: `Bearer ${token}` } },
      });
    },
    queryResult,
  ];
};
