// Send Error Data To Sentry
export const sendErrorsToSentry = (error, errorInfo) => {
  const isBrowser = typeof window !== undefined;
  if (isBrowser) {
    errorInfo &&
      window.Sentry.configureScope(scope => {
        Object.keys(errorInfo).forEach(key => {
          scope.setExtra(key, errorInfo[key]);
        });
      });
    error && window.Sentry.captureException(error);
  }
};
